<template>
		<v-card  height="100%">
				<DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
				<div class="pa-3">
						<v-toolbar>
						<v-toolbar-title>Tx Email Setting List</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-breadcrumbs :items="items"></v-breadcrumbs>
						</v-toolbar>
				</div>
				<div class="pa-3">
				<v-card>
				<v-card-title>
				<v-spacer></v-spacer>
				<v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
				<!-- <v-btn icon dark class="mb-2 success" @click="addRecord">
						<v-icon>mdi-plus</v-icon>
				</v-btn> -->
				</v-card-title>
				<v-card-text>

				<v-text-field
						v-model="tblSearch"
						append-icon="mdi-magnify"
						label="Search data below"
						hide-details
						outlined
				></v-text-field>
				</v-card-text>

				<v-data-table
				v-model="selected"  
				ref="vuetable"
				item-key="created_date"
				:headers="tblHeaders"
				:items="tblBody"
				:search="tblSearch"
				:loading = "loadingFlag"
				loading-text="Loading... Please wait"    
				@click:row="openDetail"
				></v-data-table>
				</v-card>
				</div>

		</v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import ApSettingService from "@/services/ApSettingService"
export default {
		components:{DlgMessage},
		data() {
				return{
						//breadcrumb
						items: [
							{text:'System',disabled: false,href:'#',},
							{text:'Tx Email Setting',disabled: false,href:'#',},
							{text:'List',disabled: false,href:'#',},
							],
						selected: [],
						tblHeaders:[
								{ text:"Category", value:"category" },
								{ text:"Code", value:"code" },
								{ text:"Name", value:"name" },
								{ text:"Method", value:"method" },
						],
						tblBody:[],
						tblSearch:'',
						loadingFlag : false,
						errorFlag : false,
						visible:false,
						form:{login_id:'',},

				}
		},
		async created() {
			this.refreshDataTable();
		},
		methods:{
      async refreshDataTable(){
          this.loadingFlag = true
          try{
              ApSettingService.list_tx_email(this.form).then((res) => {
                  //console.log(res);
                  this.tblBody = res.data.data;
                  this.loadingFlag = false;
                  this.errorFlag = false;
              }).catch((e)=>{
                  //console.log(e.response);
                  if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Tx Email Setting',e.response.data.error_message);
                  this.loadingFlag = false
                  this.errorFlag = true;
              })
              ;
          } catch(e) {
              this.loadingFlag = false
              if(e)
              alert(e);
              //this.$refs.dlgMsg.showDlgBox(true,'error','Tx Email Setting','Unknown Error');
            
          }           
      },
			openDetail(obj){
				//console.log(obj.sys_setting_id)
				let routeData = this.$router.resolve({ name: 'txemail-details', 
					params: { 
						email_setting_id: obj.email_setting_id,
						//enc_ns_name: btoa(obj.ns_name),
					} 
				})
				window.open(routeData.href,'_blank')
			},

        addRecord(){
            this.visible = true;
        },
        dlgClose(){
            this.visible = false;
            if(!this.errorFlag)
                this.refreshDataTable();
        },
		}

}
</script>